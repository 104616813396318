/**
 * Parses YouTube video link with video id and parameters, returns object with id and parameters
 * @param url YouTube video link as valid URL
 */
export const parseYoutubeSrc = (url: string): Record<string, string> => {
  try {
    const obj = new URL(url);
    const id = obj.pathname.split("/").at(-1) ?? "";
    const params = Object.fromEntries(new URLSearchParams(obj.searchParams));

    return { id, ...params };
  } catch {
    return {};
  }
};
