import type { FC } from "react";
import type { PublisherImprintLinkComponent } from "@motain/xpa-proto-files-web/lib/types/publisher_imprint_link";
import styles from "./PublisherImprintLink.module.scss";
import { ImageWithFallback } from "@/components/image/ImageWithFallback";
import classNames from "classnames";

export const PublisherImprintLink: FC<PublisherImprintLinkComponent> = (
  props,
) => {
  const { url, text } = props;
  return (
    <a
      className={classNames(styles.container, "title-7-medium")}
      href={url}
      rel={"noreferrer"}
      target={"_blank"}
    >
      <ImageWithFallback
        fallbackImage={null}
        width={24}
        height={24}
        alt={""}
        src={"https://images.onefootball.com/cw/icons/info-light.svg"}
        darkModeSrc={"https://images.onefootball.com/cw/icons/info-dark.svg"}
      />
      {text}
    </a>
  );
};
