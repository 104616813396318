import type { FC, ReactNode } from "react";
import type { Colors } from "@motain/xpa-proto-files-web/lib/types/colors";
import classNames from "classnames";
import styles from "./VideoPatternContainer.module.scss";
import PatternLeft from "@/public/next-assets/zigzag-pattern-left.svg";
import PatternRight from "@/public/next-assets/zigzag-pattern-right.svg";
import React from "react";

interface VideoPatternContainerProps {
  colorsDarkTheme: Colors | undefined;
  colorsLightTheme: Colors | undefined;
  children: ReactNode | Array<ReactNode>;
}

/**
 * Adds patterns and padding around provided video component.
 * If colors not provided, only styles controlling width limit will be applied.
 * */
export const VideoPatternContainer: FC<VideoPatternContainerProps> = ({
  children,
  colorsDarkTheme,
  colorsLightTheme,
}) => {
  let colorsDefined = false;
  let colorsCssVariables = {};

  if (
    typeof colorsDarkTheme !== "undefined" &&
    typeof colorsLightTheme !== "undefined"
  ) {
    colorsDefined = true;

    colorsCssVariables = {
      "--color-pattern-dark": colorsDarkTheme.pattern,
      "--color-text-dark": colorsDarkTheme.text,
      "--color-background-dark": colorsDarkTheme.background,
      "--color-pattern-light": colorsLightTheme.pattern,
      "--color-text-light": colorsLightTheme.text,
      "--color-background-light": colorsLightTheme.background,
    };
  }

  if (!colorsDefined) {
    return <div className={styles.video}>{children}</div>;
  }

  return (
    <div className={classNames(styles.container)} style={colorsCssVariables}>
      <div className={styles.patternContainers}>
        <div className={styles.patternContainerLeft}>
          <PatternLeft className={styles.pattern} />
        </div>
        <div className={styles.patternContainerRight}>
          <PatternRight className={styles.pattern} />
        </div>
      </div>
      <div className={styles.video}>
        <div className={styles.videoBackdrop}></div>
        {children}
      </div>
    </div>
  );
};
