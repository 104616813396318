import { useEffect, useRef } from "react";

export const useUnload = (
  handler: (event: BeforeUnloadEvent) => unknown,
): void => {
  const eventListenerRef = useRef<(event: BeforeUnloadEvent) => unknown>();

  useEffect(() => {
    eventListenerRef.current = (event: BeforeUnloadEvent): unknown => {
      const returnValue = handler(event);

      if (typeof returnValue === "string") {
        return (event.returnValue = returnValue);
      }

      if (event.defaultPrevented) {
        return (event.returnValue = "");
      }

      return undefined;
    };
  }, [handler]);

  useEffect(() => {
    const eventListener = (event: BeforeUnloadEvent) =>
      eventListenerRef.current?.(event);

    window.addEventListener("beforeunload", eventListener);
    return () => {
      window.removeEventListener("beforeunload", eventListener);
    };
  }, []);
};
