import React, { useCallback, useEffect, useRef, useState } from "react";
import type { Immutable } from "@/types/immutable";
import type { YoutubeComponent } from "@motain/xpa-proto-files-web/lib/types/youtube";
import {
  ClientParameterType,
  EventType,
} from "@motain/xpa-proto-files-web/lib/types/tracking";
import type { YouTubeSDK } from "./YoutubeVideo.interface";
import { YoutubeState } from "./YoutubeVideo.interface";
import type {
  YT_GLOBAL_API_READY_EV,
  YT_GLOBAL_KEY_NS,
} from "./YoutubeVideo.constants";
import { YT_SDK_URL } from "./YoutubeVideo.constants";
import { useFullScreen } from "@/utils/hooks/useFullScreen";
import { useDurationInSeconds } from "@/utils/hooks/useDurationInSeconds";
import { useUnload } from "@/utils/hooks/useUnload";
import { parseYoutubeSrc } from "./YoutubeVideo.utils";
import { trackingService } from "@/modules/analytics";
import styles from "./YoutubeVideo.module.scss";
import { VideoPatternContainer } from "@/components/video-pattern-container/VideoPatternContainer";

declare global {
  interface Window {
    [YT_GLOBAL_API_READY_EV]?: () => void;
    [YT_GLOBAL_KEY_NS]?: YouTubeSDK;
  }
}

export type XpaYoutubeComponent = Immutable<YoutubeComponent>;

export const YoutubeVideo: React.FC<XpaYoutubeComponent> = (playerData) => {
  const {
    videoId,
    videoSrc,
    trackingEvents,
    colorsLightTheme,
    colorsDarkTheme,
  } = playerData;
  const { id, ...videoOptions } = parseYoutubeSrc(videoSrc);

  const [startCounting, setStartCounting] = useState(false);
  const youtubeStateRef = useRef<YoutubeState | null>(null);
  const playerInitializedRef = useRef(false);

  const durationRef = useDurationInSeconds(startCounting);

  const fullScreenRef = useFullScreen();

  const sendTracking = useCallback(() => {
    const youtubeState = youtubeStateRef.current;

    trackingEvents.forEach((event) => {
      if (event.type === EventType.VIDEO_PLAYED) {
        const clientParams = event.clientParameterRequests.reduce<
          Record<string, string>
        >((params, param) => {
          switch (param.type) {
            case ClientParameterType.PARAMETER_VIDEO_PLAYBACK_DURATION_SECONDS:
              params[param.key] = durationRef.current.toString();
              return params;

            case ClientParameterType.PARAMETER_VIDEO_ENTERED_FULLSCREEN_MODE:
              params[param.key] =
                fullScreenRef.current.wasFullScreen.toString();

              return params;

            case ClientParameterType.PARAMETER_VIDEO_FINISHED:
              params[param.key] = String(youtubeState === YoutubeState.Ended);
              return params;

            default:
              return params;
          }
        }, {});

        trackingService.sendSingleXpaTracking(event, {
          ...clientParams,
        });
      }
    });
  }, [durationRef, trackingEvents, fullScreenRef]);

  // Send analytics when unmount component: click on the client link, e.g. Link
  useEffect(() => {
    return () => {
      if (
        playerInitializedRef.current &&
        youtubeStateRef.current === YoutubeState.Playing
      ) {
        sendTracking();
      }
    };
  }, [sendTracking]);

  // Send analytics when destroy component: click on the <a></a>
  useUnload(() => {
    if (
      playerInitializedRef.current &&
      youtubeStateRef.current === YoutubeState.Playing
    ) {
      sendTracking();
    }
  });

  useEffect(() => {
    if (playerInitializedRef.current) {
      return;
    }

    const initPlayer = () => {
      if (window.YT === undefined) {
        return;
      }

      playerInitializedRef.current = true;

      new window.YT.Player(videoId, {
        videoId: id,
        playerVars: videoOptions,
        events: {
          onStateChange: ({ data }: { data: YoutubeState }) => {
            youtubeStateRef.current = data;
            if ([YoutubeState.Ended, YoutubeState.Paused].includes(data)) {
              setStartCounting(false);
              sendTracking();
            } else if ([YoutubeState.Playing].includes(data)) {
              setStartCounting(true);
            }
          },
        },
      });
    };

    if (window.YT === undefined) {
      const tag = document.createElement("script");
      tag.src = YT_SDK_URL;

      window.onYouTubeIframeAPIReady = initPlayer;

      const firstScriptTag = document.getElementsByTagName("script")[0];

      if (firstScriptTag?.parentNode) {
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      } else {
        initPlayer();
      }
    } else {
      initPlayer();
    }
  }, [id, sendTracking, videoId, videoOptions]);

  return (
    <VideoPatternContainer
      colorsDarkTheme={colorsDarkTheme}
      colorsLightTheme={colorsLightTheme}
    >
      <div className={styles.wrapper}>
        <div id={videoId} />
      </div>
    </VideoPatternContainer>
  );
};
