import type { MutableRefObject } from "react";
import { useEffect, useRef } from "react";

export const useDurationInSeconds = (
  start: boolean,
): MutableRefObject<number> => {
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const counterRef = useRef(0);

  useEffect(() => {
    if (start) {
      timerRef.current = setInterval(() => {
        counterRef.current++;
      }, 1000);
    } else if (timerRef.current) {
      clearInterval(timerRef.current);
      counterRef.current = 0;
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [start]);

  return counterRef;
};
