import type { FC } from "react";
import { useCallback } from "react";
import type { VisibilityTrackerComponent } from "@motain/xpa-proto-files-web/lib/types/visibility_tracker";
import { EventType } from "@motain/xpa-proto-files-web/lib/types/tracking";
import { trackingService } from "@/modules/analytics";
import { notifyWhenInViewport } from "@/utils/notify-when-in-viewport";
import { isNonNullable } from "@/types/isNonNullable";

export const VisibilityTracker: FC<VisibilityTrackerComponent> = ({
  trackingEvents,
}) => {
  const setDivRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isNonNullable(node)) {
        notifyWhenInViewport(node)
          .then(() => {
            trackingService.sendXpaTracking(
              trackingEvents,
              EventType.EVENT_VISIBLE,
            );
          })
          .catch(void 0);
      }
    },
    [trackingEvents],
  );

  return (
    <div
      style={{ margin: 0 }}
      className="visibilityTracker"
      ref={setDivRef}
    ></div>
  );
};
