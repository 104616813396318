import type { RelatedNewsItem as XpaRelatedNewsItem } from "@motain/xpa-proto-files-web/lib/types/related_news";
import type { FC } from "react";
import Link from "next/link";
import { trackingService } from "@/modules/analytics";
import { EventType } from "@motain/xpa-proto-files-web/lib/types/tracking";
import styles from "./RelatedNewsItem.module.scss";
import classNames from "classnames";
import { VerifiedIcon } from "@/components/verified-icon/VerifiedIcon";

export const RelatedNewsItem: FC<XpaRelatedNewsItem> = (props) => {
  const { link, trackingEvents, title, providerName, providerIsVerified } =
    props;
  return (
    <Link
      href={link}
      onClick={() => {
        trackingService.sendXpaTracking(trackingEvents, EventType.EVENT_CLICK);
      }}
      className={classNames(styles.container, styles.listItem)}
    >
      <p className={classNames("title-7-bold", styles.title)}>{title}</p>
      <p className={classNames("title-8-regular", styles.provider)}>
        {providerName}
        {providerIsVerified && <VerifiedIcon />}
      </p>
    </Link>
  );
};
