import type { FC } from "react";
import React from "react";
import type { NativeShare } from "@motain/xpa-proto-files-web/lib/types/native_share";
import styles from "./NativeShare.module.scss";
import { Button } from "@/components/button/Button";
import { trackingService } from "@/modules/analytics";
import { EventType } from "@motain/xpa-proto-files-web/lib/types/tracking";
import { SeverityType, Snackbar } from "@/components/snackbar/Snackbar";
import { useNotification } from "@/utils/hooks/useNotification";
import useTranslation from "next-translate/useTranslation";
import { isNullable } from "@/types/isNullable";
import { remoteLogger } from "@/utils/remoteLogger";
import classNames from "classnames";

export interface NativeShareProps extends NativeShare {
  onNotify?: () => void;
  iconOnly?: boolean;
}

export const NativeShareComponent: FC<NativeShareProps> = (props) => {
  const {
    trackingEvents,
    buttonText,
    shareUrl,
    shareTitle,
    shareText,
    iconOnly = false,
  } = props;
  const { t } = useTranslation("web-payments");
  const { show, dismiss, open, ...snackbarProps } = useNotification();

  const onButtonShare = (): void => {
    const navigatorObject = window.navigator;

    if (isNullable(navigatorObject)) {
      return;
    }

    trackingService.sendXpaTracking(trackingEvents, EventType.EVENT_CLICK);

    try {
      if (typeof navigatorObject.share === "function") {
        void navigatorObject.share({
          url: shareUrl,
          title: shareTitle,
          text: shareText,
        });
      } else {
        void navigatorObject.clipboard.writeText(shareUrl);
      }

      show({
        message: t`LINK_COPIED`,
        type: SeverityType.success,
        dismissible: true,
        autoHideDuration: 3000,
      });
    } catch (error) {
      remoteLogger.error("NativeShare error", error);
    }
  };

  return (
    <>
      <Button
        variant={"outline"}
        onClick={() => {
          onButtonShare();
        }}
        className={classNames(
          styles.container,
          iconOnly && styles.containerIcon,
        )}
      >
        {iconOnly ? (
          <span className={"screen-reader-only"}>{buttonText}</span>
        ) : (
          <>{buttonText}</>
        )}

        <svg className={styles.icon} fill="currentColor" viewBox="0 0 24 24">
          <path d="M12.2377 1.1804a.75.75 0 0 0-.9762.0003l-3.51 3.01a.75.75 0 0 0 .9765 1.1386l2.292-1.9655V12.52c0 .46.33.84.73.84.4 0 .73-.37.73-.84V3.3633l2.2718 1.9463a.75.75 0 1 0 .9759-1.1392l-3.49-2.99Z" />
          <path d="M5.14 8.62c-.904 0-1.64.7358-1.64 1.6455v9.209c0 .9097.736 1.6455 1.64 1.6455h13.22c.904 0 1.64-.7358 1.64-1.6455v-9.1977c0-.9097-.736-1.6455-1.64-1.6455h-1.61a.75.75 0 0 1 0-1.5h1.61c1.736 0 3.14 1.411 3.14 3.1455v9.1977c0 1.7344-1.404 3.1455-3.14 3.1455H5.14C3.404 22.62 2 21.2089 2 19.4745v-9.209C2 8.5311 3.404 7.12 5.14 7.12h1.32a.75.75 0 1 1 0 1.5H5.14Z" />
        </svg>
      </Button>
      <Snackbar open={open} onClose={dismiss} {...snackbarProps} />
    </>
  );
};
