import type { FC } from "react";
import type { RelatedNewsComponent } from "@motain/xpa-proto-files-web/lib/types/related_news";
import styles from "./RelatedNews.module.scss";
import { isNonNullable } from "@/types/isNonNullable";
import { trackingService } from "@/modules/analytics";
import { EventType } from "@motain/xpa-proto-files-web/lib/types/tracking";
import { constructHrefFromXpaLink } from "@/utils/constructHrefFromXpaLink";
import Link from "next/link";
import classNames from "classnames";
import { RelatedNewsItem } from "@/xpa-components/related-news/related-news-item/RelatedNewsItem";

export const RelatedNews: FC<RelatedNewsComponent> = ({
  items,
  sectionHeader,
}) => {
  const seeMoreUrl = constructHrefFromXpaLink(sectionHeader?.seeMore);

  return (
    <article className={styles.container} data-testid="related-news">
      {isNonNullable(sectionHeader) && (
        <header className={classNames("title-7-bold", styles.header)}>
          {sectionHeader.title}
        </header>
      )}

      <ul className={styles.list}>
        {items.map((item) => {
          return (
            <li key={item.id} className={styles.listItem}>
              <RelatedNewsItem {...item} />
            </li>
          );
        })}
      </ul>
      {sectionHeader?.seeMore !== undefined && seeMoreUrl !== undefined && (
        <Link
          className={classNames("title-8-bold", styles.seeMoreButton)}
          href={seeMoreUrl}
          onClick={() => {
            if (isNonNullable(sectionHeader.seeMore)) {
              trackingService.sendXpaTracking(
                sectionHeader.seeMore.trackingEvents,
                EventType.EVENT_CLICK,
              );
            }
          }}
        >
          {sectionHeader.seeMore.name}
        </Link>
      )}
    </article>
  );
};
