import type { FC } from "react";
import React, { useState } from "react";
import { VideoPatternContainer } from "@/components/video-pattern-container/VideoPatternContainer";
import type { VideojsPlayerComponent } from "@motain/xpa-proto-files-web/lib/types/videojs_player";
import { VideoPlayer } from "@/components/video-player-v2";
import { BAIT_SCRIPT_URL } from "@/components/video-player-v2/constants/baitScriptUrl";
import { isNonNullable } from "@/types/isNonNullable";

const DefaultVideojsWrapper: FC<{
  videoJsOptions: VideojsPlayerComponent;
  id: string;
  shouldReInit?: boolean;
}> = ({ videoJsOptions, shouldReInit }) => {
  const [isAdBlockerActive, setIsAdBlockerActive] = useState(false);
  if (!isNonNullable(videoJsOptions.config)) {
    return null;
  }
  return (
    <VideoPatternContainer
      colorsDarkTheme={videoJsOptions.config.colorsDarkTheme}
      colorsLightTheme={videoJsOptions.config.colorsLightTheme}
    >
      <VideoPlayer
        config={videoJsOptions.config}
        playlist={videoJsOptions.playlist}
        shouldShowAdBlocker={isAdBlockerActive}
        onAdBlockerActive={() => {
          setIsAdBlockerActive(true);
        }}
        adBlockerBaitScriptUrl={BAIT_SCRIPT_URL}
        shouldReInit={shouldReInit}
      />
    </VideoPatternContainer>
  );
};

export { DefaultVideojsWrapper };
