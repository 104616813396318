export enum YoutubeState {
  Unstarted = -1,
  Ended,
  Playing,
  Paused,
  Buffering,
  VideoCued,
}

/**
 * See https://developers.google.com/youtube/iframe_api_reference
 */
export interface YouTubeSDK {
  Player: new (htmlId: string, options: Record<string, unknown>) => void;
}
